.loader {
  grid-row: 2/2;
  transform: translate3d(-50%, -50%, 0);
}
.loader-dot {
  width: 14px;
  height: 14px;
  background: #ffffff;
  border-radius: 100%;
  display: inline-block;
  animation: slide 1s infinite;
}
.loader-dot:nth-child(1) {
  animation-delay: 0.1s;
  background: #0c72ad;
}
.loader-dot:nth-child(2) {
  animation-delay: 0.2s;
  background: #669933;
}
.loader-dot:nth-child(3) {
  animation-delay: 0.3s;
  background: #ceaa28;
}
.loader-dot:nth-child(4) {
  animation-delay: 0.4s;
  background: #ef792d;
}

@-moz-keyframes slide {
  0% {
    transform: scale(1);
  }
  50% {
    opacity: 0.3;
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }
}
@-webkit-keyframes slide {
  0% {
    transform: scale(1);
  }
  50% {
    opacity: 0.3;
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }
}
@-o-keyframes slide {
  0% {
    transform: scale(1);
  }
  50% {
    opacity: 0.3;
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes slide {
  0% {
    transform: scale(1);
  }
  50% {
    opacity: 0.3;
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }
}
